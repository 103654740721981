import * as React from "react";
import PageLayout from "../../PageLayout";
import { Helmet } from "react-helmet";
import PrevNext from "../../components/PrevNext";
import SectionLayout from "../../SectionLayout";
import background from "../../images/previousprojects/5.jpg"
const EvaValley = () => {
    return (
        <PageLayout
            noButton
            navSize={"h-80 md:h-96"}
            image={background}
            title={"Eva Valley Power Station"}
            titleStyles={"xl:mt-12 sm:mt-20 xs:mt-16 mt-12"}
        >
            <Helmet>
                <title>
                    Eva Valley Power Station | EPG Solutions
                </title>
                <meta
                    name="description"
                    content="Client: Power Water Completed: 2019-20 Location: Eva Valley, Top End region (110km S of Katherine) Scope: Install and commission of a ‘Compact 1000’ fully automated three generator and three feeders control system that monitors and control the generator start/stop, synchronising and load shearing, generator protection, as well as feeder open/close commands, load sheading, and feeder."
                />
            </Helmet>
            <div className={"bg-epg-yellow h-5"} />
            <SectionLayout bgColor={"bg-epg-white"}>
                <div className={" py-5"}>
                    <div className={"px-2 mb-10  lg:leading-8 lg:text-lg"}>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Client:
                            <span
                                className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}
                            >
                Power Water
              </span>
                        </p>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Completed:
                            <span
                                className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}
                            >
                Ongoing
              </span>
                        </p>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Location:
                            <span
                                className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}
                            >
                Power Water
              </span>
                        </p>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Scope:
                            <span
                                className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}
                            >
                10-Foot chlorine disinfection containers, Installation of the
                Distribution board, Telemetry panel, Wall mounted A/C, Chlorine
                Bund and associated equipment including a pre-wired pump
                termination junction box.
                <br />
                <br />
                Automated chlorine dossing of the community drinking water.
              </span>
                        </p>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Challenges:
                            <span
                                className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}
                            >
                Remote location. Corrosiveness of chlorine required use of
                plastic and stainless steel to prevent corrosion.
              </span>
                        </p>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Outcome:
                            <span
                                className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}
                            >
                On time, on budget, happy customer.
              </span>
                        </p>
                    </div>
                    <PrevNext
                        prev={"peppimenarti-power-station/"}
                        next={"laramba-borefield-power-station/"}
                    />
                </div>
            </SectionLayout>
        </PageLayout>
    );
};

export default EvaValley;
